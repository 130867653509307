/* eslint-disable max-len */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-props-no-spreading */
import LinkWrapper from 'components/LinkWrapper/LinkWrapper'
import SwitchLink from 'components/SwitchLink/SwitchLink'
import { Fragment, memo, useState } from 'react'

/**
 * https://www.techighness.com/post/javascript-find-key-path-in-deeply-nested-object-or-array/
 */
const findPath = (ob, props) => {
  const key = Object.keys(props)[0]
  const value = Object.values(props)[0]
  const path = []
  const keyExists = (obj) => {
    if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
      return false
    }
    if (obj.hasOwnProperty(key) && obj[key] === value) {
      return true
    }
    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        path.push(obj[i][key])
        const result = keyExists(obj[i], key)
        if (result) {
          return result
        }
        path.pop()
      }
    } else {
      for (const k in obj) {
        path.push(k)
        const result = keyExists(obj[k], key)
        if (result) {
          return result
        }
        path.pop()
      }
    }

    return false
  }

  keyExists(ob)

  return path
}
const getIndexPath = (array, _id) => findPath(array, { _id }).filter((x) => x !== 'children')

const DELAY = 200
let enterTimeout
let leaveTimeout

const SeoMenuDesktop = ({ data, mobile }) => {
  const [indexPath, setIndexPath] = useState([])
  if (mobile) {
    return null
  }
  const onOpenMenu = (_id) => () => {
    clearTimeout(leaveTimeout)
    enterTimeout = setTimeout(() => {
      const path = getIndexPath(data, _id)
      setIndexPath(path)
      clearTimeout(enterTimeout)
    }, DELAY)
  }
  const onCloseMenu = (level) => () => {
    clearTimeout(enterTimeout)
    if (level !== 0) {
      return
    }
    leaveTimeout = setTimeout(() => {
      setIndexPath([])
      clearTimeout(leaveTimeout)
    }, DELAY)
  }

  const renderDesktop = (menu, parentHref, level = 0, hasActiveParent = false) => {
    if (!menu || menu.length === 0) {
      return null
    }
    const lastIndex = menu.length - 1
    return ((
      <ul>
        {menu.map(({
          _id, name, href, children,
        }, index) => {
          const nextLevel = level + 1
          const indexPathLength = indexPath.length
          const isActive = indexPath[level] === _id || (hasActiveParent && level === 1 && index === 0 && indexPathLength === 1)
          const isVisible = hasActiveParent && nextLevel === 2
          const activeClass = isActive ? 'active' : ''
          const visibleClass = isVisible ? 'visible' : ''
          const shouldRenderChildren = hasActiveParent || indexPathLength > 0
          const childrenBeingRendered = shouldRenderChildren ? children : []

          return (
            <Fragment key={_id}>
              <li
                title={name}
                key={_id}
                {...(visibleClass || activeClass) && { className: `${visibleClass} ${activeClass}`.trim() }}
                {...nextLevel <= 2 && { onMouseEnter: onOpenMenu(_id) }}
                onMouseLeave={onCloseMenu(level)}
                onClick={onCloseMenu(0)}
              >
                <SwitchLink href={href}>
                  <span {...!href && { className: 'no-link' }}>{name}</span>
                  {level === 1 && children.length > 0 && (
                    <svg className={`right-arrow ${activeClass}`.trim()} viewBox="0 0 24 24" focusable="false" aria-hidden="true"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" /></svg>
                  )}
                </SwitchLink>

                {nextLevel === 2 && (
                  <div
                    className={`submenu ${activeClass}`.trim()}
                    onMouseLeave={onCloseMenu(level)}
                  >
                    <LinkWrapper href={href}>
                      <h3 {...!href && { className: 'no-link' }}>{name}</h3>
                    </LinkWrapper>
                    {renderDesktop(childrenBeingRendered, menu[index].href, nextLevel, isActive)}
                  </div>
                )}
                {nextLevel !== 2 && (renderDesktop(childrenBeingRendered, menu[index].href, nextLevel, isActive))}
              </li>
              {level === 3 && index === lastIndex && (
                <li className="text-decoration__underline">
                  <SwitchLink href={parentHref}>
                    Visa fler
                  </SwitchLink>
                </li>
              )}
            </Fragment>
          )
        })}
      </ul>
    ))
  }

  return (
    <nav id="desktop-nav">
      {renderDesktop(data)}
      {/* <ul>
        <li>
          <LinkWrapper href="/se/om-oss">Om oss</LinkWrapper>
        </li>
      </ul> */}
    </nav>
  )
}

export default memo(SeoMenuDesktop)
