/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */

import kebabCase from 'lodash.kebabcase'

const uuidv4 = require('uuid').v4

const analytics = {
  onFilterApply: ({
    filterValue, router, predefinedFilterOption = true, filterCategory, isSearch,
  }) => {
    if (isSearch) {
      const searchTerm = router?.query?.query
      window.dataLayer.push({
        event: 'filter_apply',
        filter_category: filterCategory,
        filter_value: [filterValue],
        filter_type: predefinedFilterOption ? 'predefined' : 'custom',
        item_category: 'search',
        search_term: `${searchTerm}`,
      })
      return
    }
    const queryLength = router?.query?.params.length
    const categoryLevel = router?.query?.params.slice(0, queryLength - 1).join('/')
    window.dataLayer.push({
      event: 'filter_apply',
      filter_category: filterCategory,
      filter_value: [filterValue],
      filter_type: predefinedFilterOption ? 'predefined' : 'custom',
      item_category: categoryLevel,
    })
  },
  /**
   * @description 2.2.7 purchase (Outbound Link Click)
   */
  onPurchase: (product) => {
    const {
      name,
      price,
      brand,
      programName,
      category,
      saving,
      _id,
    } = product

    const categoryArray = category.split(',').reduce((array, value) => (
      { ...array, [`item_category${Object.keys(array).length > 0 ? Object.keys(array).length : ''}`]: value }), {})

    window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: uuidv4(),
        value: String(price),
        currency: 'SEK',
        item_location: 'detail page',
        items: [{
          item_name: name,
          item_id: _id,
          price: String(Math.round(price)),
          discount: String(Math.round(saving)),
          item_brand: brand,
          ...categoryArray,
          quantity: 1,
          store: programName,
        }],
      },
    })
  },
  onSearchInputClick: () => {
    window.dataLayer.push({
      event: 'enter_search_field',
      search_field_variant: 'top search field',
    })
  },
  onFilterOpen: (filter_category, item_category, search_term) => {
    window.dataLayer.push({
      event: 'filter_open',
      filter_category, // the chosen filter e.g. "price", "size", "sex", "color"
      item_category, // filter used in search-page
      ...search_term && { search_term },
    })
  },
  onMainMenuClick: ({ click_text }) => {
    window.dataLayer.push({
      event: 'main_menu_click',
      click_text,
    })
  },
  /**
   * @description 2.2.3 view_item_list (Product Impressions)
   */
  onViewItemList: (array, shallow, index) => {
    window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        currency: 'SEK',
        items: array.map((p) => {
          const {
            name,
            price,
            brand,
            saving,
            category,
            programName,
            _id,
          } = p

          const categoryArray = category.split(',').reduce((arr, value) => ({
            ...arr,
            [`item_category${Object.keys(arr).length > 0 ? Object.keys(arr).length : ''}`]: value,
          }), {})

          return ({
            item_name: name,
            item_id: _id,
            price: String(Math.round(price)),
            discount: String(Math.round(saving)),
            item_brand: brand,
            ...categoryArray,
            item_list_name: shallow ? 'Similar products' : category,
            item_list_id: shallow ? 'similar-products' : category.split(',').map((x) => kebabCase(x)).join('/'),
            index,
            quantity: 1,
            store: programName,
          })
        }),
      },
    })
  },
  /**
   * @description 2.2.5 view_item (Product Detail)
   */
  onViewitem: (product) => {
    const {
      brand,
      name,
      category,
      price,
      programName,
      discount,
      sku,
    } = product

    const categoryArray = category.split(',').reduce((array, value) => ({
      ...array,
      [`item_category${Object.keys(array).length > 0 ? Object.keys(array).length : ''}`]: value,
    }), {})

    window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        currency: 'SEK',
        item_location: 'quick view',
        items: [{
          item_name: name,
          item_id: sku,
          item_brand: brand,
          ...categoryArray,
          item_list_name: category,
          item_list_id: category.split(',').map((x) => kebabCase(x)).join('/'),
          index: 0,
          quantity: 1,
          price,
          discount,
          store: programName,
        }],
      },
    })
  },
  /**
   * @description 2.2.6 add_to_wishlist (Add To Favorites)
   */
  onAddToWishlist: (product) => {
    const {
      brand,
      name,
      category,
      price,
      saving,
      programName,
      _id,
    } = product

    const categoryArray = category.split(',').reduce((array, value) => ({
      ...array,
      [`item_category${Object.keys(array).length > 0 ? Object.keys(array).length : ''}`]: value,
    }), {})

    window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'add_to_wishlist',
      ecommerce: {
        currency: 'SEK',
        items: [{
          item_name: name,
          item_id: _id,
          price: String(Math.round(price)),
          discount: String(Math.round(saving)),
          item_brand: brand,
          ...categoryArray,
          item_list_name: category,
          item_list_id: category.split(',').map((x) => kebabCase(x)).join('/'),
          index: 0,
          quantity: 1,
          store: programName,
        }],
      },
    })
  },
  /**
   * @description 2.2.4 select_item (Product Click)
   */
  onSelectItem: (product) => {
    const {
      brand,
      name,
      category,
      price,
      saving,
      programName,
      _id,
    } = product

    const categoryArray = category.split(',').reduce((array, value) => ({
      ...array,
      [`item_category${Object.keys(array).length > 0 ? Object.keys(array).length : ''}`]: value,
    }), {})

    window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'select_item',
      ecommerce: {
        currency: 'SEK',
        selection_type: 'item_click',
        items: [{
          item_name: name,
          item_id: _id,
          item_brand: brand,
          ...categoryArray,
          item_list_name: category,
          item_list_id: category.split(',').map((x) => kebabCase(x)).join('/'),
          index: 0,
          quantity: 1,
          price: String(Math.round(price)),
          discount: String(Math.round(saving)),
          store: programName,
        }],
      },
    })
  },
  /**
   * @description 2.2.8 newsletter_signup
   */
  onNewsletterSignup: () => {
    window.dataLayer.push({
      event: 'newsletter_signup',
    })
  },
  /**
   * @description 2.2.2 page_view_event
   */
  onPageViewEvent: (title, url, type) => {
    window.dataLayer.push({
      event: 'page_view_event',
      title,
      url,
      type,
    })
  },
}

export default analytics
