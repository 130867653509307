import {
  useEffect, memo, Fragment, useState,
} from 'react'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import NProgress from 'nprogress'
import Router from 'next/router'
import throttle from 'lodash.throttle'
import { useDispatch, useSelector } from 'react-redux'
import { ClickAwayListener } from '@material-ui/core'
import analytics from 'analytics/sharedAnalytics'
import Script from 'next/script'
import Header from '../../components/Header/Header'
import {
  setInnerWidth, setSearchModalIsOpenAction, setSearchModalInputValueAction,
} from '../../store/actions/main'

const Footer = dynamic(import('../../components/Footer/Footer'))
// const SearchModal = dynamic(import('../../components/SearchModal/SearchModal'))

Router.onRouteChangeStart = () => {
  NProgress.start()
}
Router.onRouteChangeComplete = () => {
  NProgress.done()
}
Router.onRouteChangeError = () => {
  NProgress.done()
}

let siteIsInitiallyLoaded = false

const Template = ({
  children,
  title,
  description,
  url,
  image = 'https://jiroy.com/images/startpage/jpg/startpage.jpg',
  indexed,
  followed,
  imageWidth = 512,
  imageHeight = 512,
  imageAlt,
  ogType = 'website',
  twitterData,
  twitterCard = 'summary',
  // categories = [],
  seoMenu = [],
  // popularSearches = [],
  className,
  structuredData,
  blackBody,
  // salesPeriods = [],
  canonical,
  hideFooter = false,
  irSiteVerification = false,
  copyrightedSiteVerification = false,
}) => {
  const dispatch = useDispatch()
  // const searchModalIsOpen = useSelector((state) => state.main.searchModalIsOpen)
  const searchModalInputValue = useSelector((state) => state.main.searchModalInputValue)
  // const [searchModalHasClosingClass, setSearchModalHasClosingClass] = useState(false)
  // const [searchModalHasOpenClass, setSearchModalHasOpenClass] = useState(false)
  const [categoryDrawerIsOpen, setCategoryDrawerIsOpen] = useState(false)
  const addBackgroundShadow = searchModalInputValue.length > 0
  useEffect(() => {
    document.body.classList.remove('preload')

    const handleResize = throttle(() => {
      dispatch(setInnerWidth(window.innerWidth))
    }, 250)
    dispatch(setInnerWidth(window.innerWidth))

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [dispatch])

  // useEffect(() => {
  // if (searchModalIsOpen === true) {
  //   setSearchModalHasOpenClass(true)
  //   return
  // }

  // setSearchModalHasOpenClass(false)
  // setSearchModalHasClosingClass(false)
  // }, [searchModalIsOpen, searchModalInputValue.length])

  useEffect(() => {
    if (!blackBody) {
      document.body.style.backgroundColor = '#fff'
    } else {
      document.body.style.backgroundColor = '#111'
    }
  }, [blackBody])

  useEffect(() => {
    let type = 'dynamic'
    if (!siteIsInitiallyLoaded) {
      type = 'hard'
      siteIsInitiallyLoaded = true
    }

    analytics.onPageViewEvent(title, url, type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  const closeSearchModal = () => {
    dispatch(setSearchModalInputValueAction(''))
    dispatch(setSearchModalIsOpenAction(false))
    // setSearchModalHasOpenClass(false)
  }

  const onToggleCategoryDrawer = () => {
    setCategoryDrawerIsOpen((prevState) => !prevState)
  }

  const onCloseCategorDrawer = () => {
    setCategoryDrawerIsOpen(false)
  }

  return (
    <div id="jiroy" className={className}>
      <Head>
        {title && (
          <>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta name="twitter:title" content={title} />
          </>
        )}
        {url && <meta property="og:url" content={url} />}
        {/* {!canonical && <link rel="canonical" href={url} />} */}
        {canonical && <link rel="canonical" href={canonical} />}
        {description && (
          <>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            <meta name="twitter:description" content={description} />
          </>
        )}
        {image && (
          <>
            <meta name="image" content={image} />
            <meta property="og:image" content={image} />
            <meta name="twitter:image" content={image} />
          </>
        )}
        {imageAlt && <meta property="og:image:alt" content={imageAlt} />}
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta name="robots" content={`${indexed ? 'index' : 'noindex'},${followed ? 'follow' : 'nofollow'}`} />
        <meta name="fo-verify" content="f52d1312-f13e-4736-9827-21795c93b082" />
        {irSiteVerification && <meta name="ir-site-verification" content="-270626810" />}
        {copyrightedSiteVerification && <meta name="copyrighted-site-verification" content="d84c65ec96f1ab39" />}

        <meta property="og:type" content={ogType} />
        <meta property="og:image:width" content={imageWidth} />
        <meta property="og:image:height" content={imageHeight} />
        <meta name="twitter:card" content={twitterCard} />
        <meta name="twitter:site" content="@Jiroy_Shopping" />

        {twitterData?.label1 && <meta name="twitter:label1" content={twitterData.label1} />}
        {twitterData?.data1 && <meta name="twitter:data1" content={twitterData.data1} />}
        {twitterData?.label2 && <meta name="twitter:label2" content={twitterData.label2} />}
        {twitterData?.data2 && <meta name="twitter:data2" content={twitterData.data2} />}
        {/* Start tag.heylink.com */}
        {/* <script src="https://tag.heylink.com/14c2ec32-cfe0-45f7-b7fc-6e81a9c4bfa5/script.js" defer /> */}
        {/* End tag.heylink.com */}

        {/* <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6463348551969142"
          crossOrigin="anonymous"
        /> */}
      </Head>

      {structuredData?.webSite && (
        <Script
          id="structured-data__website"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'WebSite',
              name: 'Jiroy',
              url: 'https://jiroy.com',
              potentialAction: {
                '@type': 'SearchAction',
                target: {
                  '@type': 'EntryPoint',
                  urlTemplate: 'https://jiroy.com/se/search?query={search_term_string}',
                },
                'query-input': 'required name=search_term_string',
              },
            }, null, 2),
          }}
        />
      )}
      {structuredData?.breadcrumb && (
        <Script
          id="script-breadcrumbList"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: structuredData.breadcrumb.map((x, position) => ({
                '@type': 'ListItem',
                position,
                ...(position < structuredData.breadcrumb.length - 1) && { item: `https://jiroy.com${x.path}` },
                name: x.name,
              })),
            }, null, 2),
          }}
        />
      )}
      {structuredData?.product && (
        <Script
          id="script-product"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Product',
              image: structuredData.product.image,
              name: structuredData.product.name,
              description: structuredData.product.description,
              sku: structuredData.product.sku,
              offers: {
                ...(structuredData.product.offers.availability && {
                  availability: structuredData.product.offers.availability,
                }),
                price: structuredData.product.offers.price,
                priceCurrency: structuredData.product.offers.priceCurrency,
                url: structuredData.product.offers.url,
              },
            }, null, 2),
          }}
        />
      )}

      <ClickAwayListener onClickAway={closeSearchModal}>
        <div>
          <Header
            seoMenu={seoMenu}
            className={className}
            categoryDrawerIsOpen={categoryDrawerIsOpen}
            onToggleCategoryDrawer={onToggleCategoryDrawer}
            onCloseCategorDrawer={onCloseCategorDrawer}
            onCloseSearchModal={closeSearchModal}
          />
        </div>
      </ClickAwayListener>
      {children}
      {!hideFooter && <Footer /* categories={categories} salesPeriods={salesPeriods} */ />}
      <div className={`bg-layer ${addBackgroundShadow || categoryDrawerIsOpen ? 'active' : ''}`} />
    </div>
  )
}

export default memo(Template)
