import React, { useEffect } from 'react'
import { Drawer } from '@material-ui/core'
import useDimensions from 'Hooks/useDimensions'

const MuiDrawer = (props) => {
  const { open } = props
  const { windowWidth } = useDimensions()

  useEffect(() => {
    const body = document.querySelector('body')

    if (open && windowWidth <= 1300) {
      body.classList.add('no-scroll')
    } else {
      body.classList.remove('no-scroll')
    }
  }, [open, windowWidth])

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Drawer {...props} />
  )
}

export default MuiDrawer
