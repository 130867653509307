import { memo } from 'react'
import { ClickAwayListener } from '@material-ui/core'
import useDimensions from 'Hooks/useDimensions'
import SeoMenuMobile from 'components/SeoMenu/SeoMenuMobile/SeoMenuMobile'
// import LinkWrapper from 'components/LinkWrapper/LinkWrapper'

const CategoryDrawer = ({
  onToggleCategoryDrawer, onCloseCategorDrawer, isOpen, seoMenu, rendered = false,
}) => {
  const { isMobile } = useDimensions({ mobileBreakPoint: 1401, throttleMs: 250 })
  if (!rendered || seoMenu?.length === 0) {
    return <div />
  }
  const openClass = isOpen ? 'isOpen' : 'closed'
  const buttonTitle = isOpen ? 'Stäng huvudmeny' : 'Huvudmeny'

  const closeCategorDrawer = () => {
    onCloseCategorDrawer?.()
  }

  if (!isMobile) {
    return null
  }

  return (
    <ClickAwayListener onClickAway={closeCategorDrawer}>
      <div>
        <button
          type="button"
          data-testid="main-menu"
          title={buttonTitle}
          className="cheeseburger-button"
          onClick={onToggleCategoryDrawer}
        >
          <div className={`menu-toggle ${openClass}`}>
            <span />
            <span />
            <span />
          </div>
        </button>
        <nav id="nav" className={`menu-toggle ${openClass}`.trim()}>
          <SeoMenuMobile data={seoMenu} onCloseNavigation={closeCategorDrawer} />
          {/* <ul className="visible">
            <li title="Våra butiker" onClick={closeCategorDrawer}>
              <LinkWrapper href="/se/om-oss">Om oss</LinkWrapper>
            </li>
          </ul> */}
        </nav>
      </div>
    </ClickAwayListener>
  )
}

export default memo(CategoryDrawer)
