import { useState } from 'react'
import Image from 'next/image'

const errorPath = '/no-image.svg'

const excludedDomains = [
  'media.storochliten.se',
  'media.babyland.se',
  'www.komplett.se',
  'hultens-litium.azureedge.net',
  'media.ginza.se',
  'media.meds.se',
  'www.gardenstore.se',
  'www.yves-rocher.se',
  'photos6.spartoo.se',
  'www.sephora.se',
]

const SkeletonImage = ({
  width,
  height,
  objectFit = 'contain',
  layout = 'intrinsic',
  src = errorPath,
  alt = '',
  priority = false,
  className = '',
  quality = 80,
  style,
  loading,
  unoptimized = false,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)
  const programIsExcludedFromOptimization = src.includes('.svg') || excludedDomains.includes(src.replace(/https?:\/\/|\/(.*)/ig, ''))

  const getSource = () => {
    if (src === '' || isError) {
      return errorPath
    }
    return src
  }
  const source = getSource()

  // eslint-disable-next-line no-nested-ternary
  const isLoadedClass = isError ? 'img-error' : isLoaded ? 'loaded' : 'not-loaded'
  // const loader = () => `https://image.jiroy.com/v1/image?url=${encodeURIComponent(src)}&w=${width}&q=${quality}`

  return (
    <div className={`skeleton-wrapper ${isLoadedClass} ${className}`} style={style}>
      <Image
        unoptimized={isError || unoptimized || programIsExcludedFromOptimization}
        // loader={loader}
        quality={quality}
        priority={priority}
        width={width}
        height={height}
        objectFit={objectFit}
        src={source}
        alt={alt}
        onLoad={() => setIsLoaded(true)}
        onError={() => setIsError(true)}
        loading={loading}
        layout={layout}
      // onClick={onImageClick}
      />
    </div>
  )
}

export default SkeletonImage
