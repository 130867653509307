/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import {
  memo,
} from 'react'
import Link from 'next/link'
import { useSelector } from 'react-redux'
import useDimensions from 'Hooks/useDimensions'
import SeoMenuDesktop from 'components/SeoMenu/SeoMenuDesktop/SeoMenuDesktop'
import CategoryDrawer from '../CategoryDrawer/CategoryDrawer'
import FavoriteItemsDrawer from '../FavoriteItemsDrawer/FavoriteItemsDrawer'
// import SvgIcons from '../../icons/svg-icons'
import SearchInput from '../SearchInput/SearchInput'
import NoLongerActiveComponent from 'components/NoLongerActiveComponent/NoLongerActiveComponent'

const Header = ({
  onCloseSearchModal, onToggleCategoryDrawer, onCloseCategorDrawer, categoryDrawerIsOpen, seoMenu,
}) => {
  const searchModalIsOpen = useSelector((state) => state.main.searchModalIsOpen)
  const searchModalInputValue = useSelector((state) => state.main.searchModalInputValue)
  const { isMobile: megaMenuForDesktop } = useDimensions({ mobileBreakPoint: 1401, throttleMs: 250, defaultIsMobile: false })
  const { isMobile: isSmallerDevice } = useDimensions({ mobileBreakPoint: 991, throttleMs: 250, defaultIsMobile: true })

  const searchFieldIsOpen = searchModalIsOpen || searchModalInputValue.length >= 1

  // const searchInputValueIsEmpty = searchModalInputValue.length === 0

  const onLogoClick = () => {
    onCloseSearchModal?.()
  }

  const toggleCategoryDrawer = () => {
    onToggleCategoryDrawer?.()
  }

  const closeCategorDrawer = () => {
    onCloseCategorDrawer?.()
  }

  if (isSmallerDevice) {
    return (
      <>
        <header id="mobile-menu" className={`${searchFieldIsOpen ? 'search-open' : ''}`}>
          <div className="first-row">

            <CategoryDrawer
              rendered={megaMenuForDesktop}
              seoMenu={seoMenu}
              isOpen={categoryDrawerIsOpen}
              onToggleCategoryDrawer={toggleCategoryDrawer}
              onCloseCategorDrawer={closeCategorDrawer}
            />

            <Link href="/">
              <a className="jiroy-logo" title="Gå till startsidan" aria-label="Gå till startsidan" onClick={onLogoClick}>
                <svg className="jiroy-logo" viewBox="0 0 497.1 90.6" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                  <path title="letter-j" d="M28.3,57c0,7.7-.8,13.1-2.5,16.2-1.6,3.1-4.4,4.6-8.4,4.6-2.9,0-6-1.1-9.3-3.3L1.4,86.1c5,3,10.3,4.5,16,4.5c7.5,0,13.6-2.7,18.2-8c2.3-2.7,4-6,5-9.9c1-3.4,1.5-8.7,1.5-15.6v-54.9h-13.8v54.8Z" />
                  <path title="letter-i" d="M73.2,2.5v57.1h-13.2v-57.1h13.2Z" />
                  <path title="letter-r" d="M91.3,2.5h13.1v5.1c2.4-2.5,4.6-4.3,6.4-5.2c1.9-1,4.2-1.5,6.8-1.5c3.5,0,7.1,1.1,10.9,3.4l-6,12.1c-2.5-1.8-4.9-2.7-7.3-2.7-7.2,0-10.8,5.4-10.8,16.3v29.6h-13.2v-57.1h.1Z" />
                  <path title="letter-o" d="M132.4,30.6c0-8.2,2.9-15.3,8.8-21c5.9-5.8,13.1-8.7,21.6-8.7s15.7,2.9,21.7,8.7c5.9,5.8,8.8,13,8.8,21.4c0,8.6-3,15.7-8.8,21.5-5.9,5.7-13.2,8.6-21.9,8.6-8.6,0-15.7-2.9-21.5-8.8-5.8-5.7-8.7-12.9-8.7-21.7Zm13.5.3c0,5.7,1.5,10.2,4.6,13.5c3.1,3.4,7.2,5,12.4,5s9.3-1.7,12.4-5s4.6-7.8,4.6-13.3-1.5-10-4.6-13.3c-3.1-3.4-7.2-5-12.4-5-5,0-9.1,1.7-12.2,5-3.2,3.4-4.8,7.8-4.8,13.1Z" />
                  <polygon title="letter-y" points="222.3,76.9 260.7,2.5 245.8,2.5 229.5,35.3 212.1,2.5 196.8,2.5 222.5,49 207.5,76.9 60,76.9 60,90.1 200.5,90.1 215.5,90.1 495.5,90.1 495.5,76.9" />
                  <path title="letter-dot" d="M258.1,53c0-2.2.8-4.1,2.4-5.7s3.5-2.4,5.7-2.4s4.1.8,5.7,2.4s2.4,3.5,2.4,5.7c0,2.3-.8,4.2-2.4,5.8s-3.5,2.3-5.7,2.3c-2.3,0-4.2-.8-5.8-2.3s-2.3-3.4-2.3-5.8Z" />
                  <path title="letter-c" d="M328.5,47.4c-2.4,1.4-5.2,2.1-8.4,2.1-5,0-9-1.7-12.2-5.2-3.2-3.4-4.7-7.8-4.7-13.2c0-5.2,1.6-9.6,4.9-13.1s7.4-5.2,12.4-5.2c3.2,0,5.9.7,8.3,2.1.5.3,1.1.7,1.7,1.1l5.8-11.4c-4.8-2.5-9.9-3.7-15.3-3.7-8.8,0-16.1,2.9-22.1,8.8s-9,13-9,21.6c0,8.5,3,15.6,8.9,21.4c5.9,5.7,13.3,8.6,22.1,8.6c4.8,0,9.7-1.1,14.5-3.4l-5.6-11.1c-.6.2-1,.4-1.3.6Z" />
                  <path title="letter-o" d="M343.4,30.6c0-8.2,2.9-15.3,8.8-21c5.9-5.8,13.1-8.7,21.6-8.7s15.7,2.9,21.7,8.7c5.9,5.8,8.8,13,8.8,21.4c0,8.6-2.9,15.7-8.8,21.5-5.9,5.7-13.2,8.6-21.9,8.6-8.6,0-15.7-2.9-21.5-8.8-5.8-5.7-8.7-12.9-8.7-21.7Zm13.5.3c0,5.7,1.5,10.2,4.6,13.5c3.1,3.4,7.2,5,12.4,5s9.3-1.7,12.4-5s4.6-7.8,4.6-13.3-1.5-10-4.6-13.3c-3.1-3.4-7.2-5-12.4-5-5,0-9.1,1.7-12.2,5-3.3,3.4-4.8,7.8-4.8,13.1Z" />
                  <path title="letter-m" d="M417.2,2.5h13.2v5.3c2.5-2.7,4.7-4.5,6.5-5.4c1.9-1,4.3-1.5,7.2-1.5c6.4,0,11.5,2.8,15.3,8.4c4.1-5.6,9.7-8.4,16.8-8.4c12.9,0,19.3,7.8,19.3,23.4v35.3h-13.2v-31.7c0-5.5-.7-9.3-2-11.6-1.4-2.3-3.6-3.5-6.7-3.5-3.6,0-6.3,1.4-7.9,4.1-1.7,2.7-2.5,7.1-2.5,13.2v29.5h-13.2v-31.5c0-10.2-2.9-15.2-8.8-15.2-3.7,0-6.4,1.4-8.1,4.2s-2.5,7.1-2.5,13.1v29.5h-13.2v-57.2h-.2Z" />

                  <g id="loading-bar" transform="translate(476.649994,83.5)">
                    <polyline points="457.8,76.9 457.8,90.1 470,90.1 471.3,90.1 495.5,90.1 495.5,76.9 471.8,76.9" transform="translate(-476.649994,-83.5)" />
                  </g>

                </svg>
              </a>
            </Link>

            <div className="right-buttons">

              <button type="button" className="login-button">
                <svg viewBox="0 0 24 24" fill="#fff">
                  <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M7.35,18.5C8.66,17.56,10.26,17,12,17 s3.34,0.56,4.65,1.5C15.34,19.44,13.74,20,12,20S8.66,19.44,7.35,18.5z M18.14,17.12L18.14,17.12C16.45,15.8,14.32,15,12,15 s-4.45,0.8-6.14,2.12l0,0C4.7,15.73,4,13.95,4,12c0-4.42,3.58-8,8-8s8,3.58,8,8C20,13.95,19.3,15.73,18.14,17.12z" />
                  <path d="M12,6c-1.93,0-3.5,1.57-3.5,3.5S10.07,13,12,13s3.5-1.57,3.5-3.5S13.93,6,12,6z M12,11c-0.83,0-1.5-0.67-1.5-1.5 S11.17,8,12,8s1.5,0.67,1.5,1.5S12.83,11,12,11z" />
                </svg>
              </button>

              <FavoriteItemsDrawer />
            </div>
          </div>
          <div className="second-row">
            <div className="search-field">
              <SearchInput placeholder="Välkommen! Vad söker du idag?" />
            </div>
          </div>
          <NoLongerActiveComponent />
        </header>
        <SeoMenuDesktop data={seoMenu} mobile={megaMenuForDesktop} />
      </>
    )
  }

  return (
    <>
      <header
        id="main-menu"
        {...searchFieldIsOpen && { className: 'search-open' }}
      >
        <div className="container">

          <Link href="/">
            <a className="jiroy-logo" title="Gå till startsidan" aria-label="Gå till startsidan" onClick={onLogoClick}>
              <svg className="jiroy-logo" viewBox="0 0 497.1 90.6" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                <path title="letter-j" d="M28.3,57c0,7.7-.8,13.1-2.5,16.2-1.6,3.1-4.4,4.6-8.4,4.6-2.9,0-6-1.1-9.3-3.3L1.4,86.1c5,3,10.3,4.5,16,4.5c7.5,0,13.6-2.7,18.2-8c2.3-2.7,4-6,5-9.9c1-3.4,1.5-8.7,1.5-15.6v-54.9h-13.8v54.8Z" />
                <path title="letter-i" d="M73.2,2.5v57.1h-13.2v-57.1h13.2Z" />
                <path title="letter-r" d="M91.3,2.5h13.1v5.1c2.4-2.5,4.6-4.3,6.4-5.2c1.9-1,4.2-1.5,6.8-1.5c3.5,0,7.1,1.1,10.9,3.4l-6,12.1c-2.5-1.8-4.9-2.7-7.3-2.7-7.2,0-10.8,5.4-10.8,16.3v29.6h-13.2v-57.1h.1Z" />
                <path title="letter-o" d="M132.4,30.6c0-8.2,2.9-15.3,8.8-21c5.9-5.8,13.1-8.7,21.6-8.7s15.7,2.9,21.7,8.7c5.9,5.8,8.8,13,8.8,21.4c0,8.6-3,15.7-8.8,21.5-5.9,5.7-13.2,8.6-21.9,8.6-8.6,0-15.7-2.9-21.5-8.8-5.8-5.7-8.7-12.9-8.7-21.7Zm13.5.3c0,5.7,1.5,10.2,4.6,13.5c3.1,3.4,7.2,5,12.4,5s9.3-1.7,12.4-5s4.6-7.8,4.6-13.3-1.5-10-4.6-13.3c-3.1-3.4-7.2-5-12.4-5-5,0-9.1,1.7-12.2,5-3.2,3.4-4.8,7.8-4.8,13.1Z" />
                <polygon title="letter-y" points="222.3,76.9 260.7,2.5 245.8,2.5 229.5,35.3 212.1,2.5 196.8,2.5 222.5,49 207.5,76.9 60,76.9 60,90.1 200.5,90.1 215.5,90.1 495.5,90.1 495.5,76.9" />
                <path title="letter-dot" d="M258.1,53c0-2.2.8-4.1,2.4-5.7s3.5-2.4,5.7-2.4s4.1.8,5.7,2.4s2.4,3.5,2.4,5.7c0,2.3-.8,4.2-2.4,5.8s-3.5,2.3-5.7,2.3c-2.3,0-4.2-.8-5.8-2.3s-2.3-3.4-2.3-5.8Z" />
                <path title="letter-c" d="M328.5,47.4c-2.4,1.4-5.2,2.1-8.4,2.1-5,0-9-1.7-12.2-5.2-3.2-3.4-4.7-7.8-4.7-13.2c0-5.2,1.6-9.6,4.9-13.1s7.4-5.2,12.4-5.2c3.2,0,5.9.7,8.3,2.1.5.3,1.1.7,1.7,1.1l5.8-11.4c-4.8-2.5-9.9-3.7-15.3-3.7-8.8,0-16.1,2.9-22.1,8.8s-9,13-9,21.6c0,8.5,3,15.6,8.9,21.4c5.9,5.7,13.3,8.6,22.1,8.6c4.8,0,9.7-1.1,14.5-3.4l-5.6-11.1c-.6.2-1,.4-1.3.6Z" />
                <path title="letter-o" d="M343.4,30.6c0-8.2,2.9-15.3,8.8-21c5.9-5.8,13.1-8.7,21.6-8.7s15.7,2.9,21.7,8.7c5.9,5.8,8.8,13,8.8,21.4c0,8.6-2.9,15.7-8.8,21.5-5.9,5.7-13.2,8.6-21.9,8.6-8.6,0-15.7-2.9-21.5-8.8-5.8-5.7-8.7-12.9-8.7-21.7Zm13.5.3c0,5.7,1.5,10.2,4.6,13.5c3.1,3.4,7.2,5,12.4,5s9.3-1.7,12.4-5s4.6-7.8,4.6-13.3-1.5-10-4.6-13.3c-3.1-3.4-7.2-5-12.4-5-5,0-9.1,1.7-12.2,5-3.3,3.4-4.8,7.8-4.8,13.1Z" />
                <path title="letter-m" d="M417.2,2.5h13.2v5.3c2.5-2.7,4.7-4.5,6.5-5.4c1.9-1,4.3-1.5,7.2-1.5c6.4,0,11.5,2.8,15.3,8.4c4.1-5.6,9.7-8.4,16.8-8.4c12.9,0,19.3,7.8,19.3,23.4v35.3h-13.2v-31.7c0-5.5-.7-9.3-2-11.6-1.4-2.3-3.6-3.5-6.7-3.5-3.6,0-6.3,1.4-7.9,4.1-1.7,2.7-2.5,7.1-2.5,13.2v29.5h-13.2v-31.5c0-10.2-2.9-15.2-8.8-15.2-3.7,0-6.4,1.4-8.1,4.2s-2.5,7.1-2.5,13.1v29.5h-13.2v-57.2h-.2Z" />

                <g id="loading-bar" transform="translate(476.649994,83.5)">
                  <polyline points="457.8,76.9 457.8,90.1 470,90.1 471.3,90.1 495.5,90.1 495.5,76.9 471.8,76.9" transform="translate(-476.649994,-83.5)" />
                </g>

              </svg>
            </a>
          </Link>

          <div className="search-field">
            <SearchInput placeholder="Välkommen! Vad söker du idag?" />
          </div>

          <div className="right-buttons">
            <button type="button" className="login-button">
              <svg viewBox="0 0 24 24" fill="#fff">
                <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M7.35,18.5C8.66,17.56,10.26,17,12,17 s3.34,0.56,4.65,1.5C15.34,19.44,13.74,20,12,20S8.66,19.44,7.35,18.5z M18.14,17.12L18.14,17.12C16.45,15.8,14.32,15,12,15 s-4.45,0.8-6.14,2.12l0,0C4.7,15.73,4,13.95,4,12c0-4.42,3.58-8,8-8s8,3.58,8,8C20,13.95,19.3,15.73,18.14,17.12z" />
                <path d="M12,6c-1.93,0-3.5,1.57-3.5,3.5S10.07,13,12,13s3.5-1.57,3.5-3.5S13.93,6,12,6z M12,11c-0.83,0-1.5-0.67-1.5-1.5 S11.17,8,12,8s1.5,0.67,1.5,1.5S12.83,11,12,11z" />
              </svg>
            </button>

            <FavoriteItemsDrawer />

            <CategoryDrawer
              rendered={megaMenuForDesktop}
              seoMenu={seoMenu}
              isOpen={categoryDrawerIsOpen}
              onToggleCategoryDrawer={toggleCategoryDrawer}
              onCloseCategorDrawer={closeCategorDrawer}
            />

          </div>
        </div>
      </header>
      <SeoMenuDesktop data={seoMenu} mobile={megaMenuForDesktop} />
      <NoLongerActiveComponent />
    </>
  )
}

export default memo(Header)
