import { useState } from "react";

const getIsSubscriberValueCorrect = (email) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
}

const SubscriberInput = () => {
  const [subscriber, setSubscriber] = useState("")

  const handleOnClick = async () => {
    const isSubscriberValueCorrect = getIsSubscriberValueCorrect(subscriber)
    if (!isSubscriberValueCorrect) {
      alert("The email address you entered is not valid.")
      return
    }

    await fetch("/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ subscriber })
    })

    setSubscriber("")
    alert("Good choice, we'll be in touch!")
  }

  const handleOnChange = (e) => {
    setSubscriber(e.target.value)
  }

  return <>
    <div className="subscriber-input__content__lower-section__left">
      <input
        type="text"
        placeholder="E-mail"
        onChange={handleOnChange}
        value={subscriber} />
    </div>
    <button onClick={handleOnClick}>Sign up</button>
  </>
}

export default SubscriberInput