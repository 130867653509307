/* eslint-disable max-len */
import { useState } from 'react'
import useResizeHandler from './useResizeHandler'

const useDimensions = (props = {}) => {
  const { mobileBreakPoint = 600, throttleMs = 1000, defaultIsMobile = true } = props
  const [windowHeight, setWindowHeight] = useState(0)
  const [windowWidth, setWindowWidth] = useState(0)
  const [isMobile, setIsMobile] = useState(defaultIsMobile)

  useResizeHandler({
    runOnMount: true,
    throttleMs,
    callback: () => {
      setWindowHeight(window.innerHeight)
      setWindowWidth(window.innerWidth)
      setIsMobile((window.innerWidth <= mobileBreakPoint))
    },
  })

  return { isMobile, windowHeight, windowWidth }
}

export default useDimensions
