/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import LinkWrapper from 'components/LinkWrapper/LinkWrapper'
import SwitchLink from 'components/SwitchLink/SwitchLink'
import { useState, memo } from 'react'

const SeoMenuMobile = ({ data, onCloseNavigation }) => {
  const [isOpen, setIsOpen] = useState([])

  const onOpenCategory = (_id, level) => (e) => {
    e.stopPropagation()
    const openOpemCopy = [...isOpen]
    openOpemCopy[level] = _id
    setIsOpen(openOpemCopy.slice(0, level + 1))
  }

  const onGoBack = (e) => {
    e.stopPropagation()
    setIsOpen([...isOpen].slice(0, -1))
  }

  const onGoToStart = (e) => {
    e.stopPropagation()
    setIsOpen([])
  }

  const onGoBackTo = (index) => (e) => {
    e.stopPropagation()
    if (index === 0) {
      setIsOpen([])
      return
    }
    setIsOpen([...isOpen].slice(0, index + 1))
  }

  const onCloseNavigationClick = (e) => {
    e.stopPropagation()
    setIsOpen([])
    onCloseNavigation?.()
  }

  const renderMobile = (menu, parentName, parentHref, level = 0, parentIsActive = false, breadCrumbs = []) => {
    if (!menu || menu.length === 0) {
      return null
    }
    const UlVisible = (level === 0 || parentIsActive) ? 'visible' : undefined
    const firstChildVisible = isOpen.length === level ? 'visible' : ''
    const showAllButtonIsVisible = level > 0 && isOpen.length === level

    if (!UlVisible) {
      return null
    }
    return (
      <ul className={UlVisible}>
        {level > 0 && (
          <ul className={`breadcrumbs ${firstChildVisible}`.trim()}>
            {breadCrumbs.map((name, index) => <li key={name} onClick={onGoBackTo(index)}>{name}</li>)}
          </ul>
        )}
        {parentName && (
          <li className={`category-title ${firstChildVisible}`.trim()}>
            <div type="button" onClick={onGoBack} title="Backa">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
                <title>tillbakapil</title>
                <path d="M7 15.999H25" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 9L7 16L14 23" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <div>{parentName}</div>
            <div onClick={onGoToStart}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 308.667 308.667">
                <title>Gå till start</title>
                <path d="M303.248,141.252l-36.887-36.887-.011-.011L167.415,5.418a18.5,18.5,0,0,0-26.162,0L5.419,141.252a18.5,18.5,0,1,0,26.162,26.163l5.331-5.331V290.167a18.481,18.481,0,0,0,18.5,18.5H253.275a18.5,18.5,0,0,0,18.5-18.5V162.1l5.311,5.311a18.5,18.5,0,1,0,26.162-26.163ZM135.987,206.807h36.6v64.86h-36.6Zm98.788,64.86H209.589v-83.36a18.48,18.48,0,0,0-18.5-18.5h-73.6a18.475,18.475,0,0,0-18.5,18.5v83.36H73.912V125.084l80.422-80.421L234.775,125.1Z" />
              </svg>
            </div>
          </li>
        )}
        {showAllButtonIsVisible && (
          <li className="visible">
            {parentName && (
              <SwitchLink
                href={parentHref}
                onClick={onCloseNavigationClick}
              >
                <span className="font-weight__500">
                  {`${level <= 1 ? 'Gå till' : 'Visa alla i'} ${parentName}`}
                </span>
              </SwitchLink>
            )}
          </li>
        )}
        {menu.map(({
          _id, name, children, href,
        }) => {
          const isActive = isOpen.includes(_id)
          const childrenBreadcrumbs = level === 0 ? ['Hem'] : [...breadCrumbs, name]
          return (
            <li
              title={name}
              key={_id}
              {...children.length > 0 && { onClick: onOpenCategory(_id, level) }}
              {...(isActive || isOpen.length === level) && { className: 'visible' }}
            >
              <LinkWrapper
                {...children.length === 0 && { href, onClick: onCloseNavigationClick }}
              >
                <span className={`first-child ${firstChildVisible}`.trim()}>{name}</span>
                {children.length > 0 && (
                  <span className={`first-child ${firstChildVisible}`.trim()}>
                    <svg className="svg__go-next" viewBox="0 0 24 24" focusable="false" aria-hidden="true">
                      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                    </svg>
                  </span>
                )}
                {renderMobile(children, name, href, level + 1, isActive, childrenBreadcrumbs)}
              </LinkWrapper>
            </li>
          )
        })}
        {showAllButtonIsVisible && (
          <li
            className="visible"
            title="Visa fler"
            onClick={onCloseNavigationClick}
          >
            <LinkWrapper href={parentHref}>
              <span>Visa fler</span>
            </LinkWrapper>
          </li>
        )}
      </ul>
    )
  }

  return (
    <>
      {isOpen.length === 0 && <span style={{ fontSize: '1.2rem' }}>Huvudkategorier</span>}
      {renderMobile(data)}
    </>
  )
}

export default memo(SeoMenuMobile)
