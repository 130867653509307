/* eslint-disable react/jsx-props-no-spreading */
import Link from 'next/link'

const LinkWrapper = ({
  href, children, onClick, className, dataAos, title, shallow = false, onMouseEnter, onMouseLeave, rel, target,
}) => {
  if (!href) {
    return children
  }
  return (
    <Link href={href} shallow={shallow}>
      <a
        target={target}
        rel={rel}
        data-aos={dataAos}
        onClick={(e) => onClick?.(e)}
        className={className}
        title={title}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}

      </a>
    </Link>
  )
}

export default LinkWrapper
