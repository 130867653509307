import throttle from 'lodash.throttle'
import { useEffect } from 'react'

const useResizeHandler = ({ callback, throttleMs, runOnMount = false }) => {
  useEffect(() => {
    const handleResize = throttle(() => {
      callback()
    }, [throttleMs])

    if (runOnMount) {
      handleResize()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throttleMs, runOnMount])

  return null
}

export default useResizeHandler
