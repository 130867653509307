/* eslint-disable no-underscore-dangle */
import { hrefWithSalesQuery, switchKeys } from 'helper'
import Link from 'next/link'
import { useSelector } from 'react-redux'

const SwitchLink = ({
  href, children, onClick, title,
  ariaLabel, className, style, contrariwise, queryKey: key,
}) => {
  const _active = useSelector((state) => state.main.switchKeys[key])
  const _isOnSale = useSelector((state) => state.main.switchKeys[switchKeys.SALES.key])
  const _isInStock = useSelector((state) => state.main.switchKeys[switchKeys.IN_STOCK.key])

  const active = !contrariwise ? _active : !_active
  const isOnSale = !contrariwise ? _isOnSale : !_isOnSale
  const isInStock = !contrariwise ? _isInStock : !_isInStock

  if (!href) {
    return children
  }

  return (
    <Link href={hrefWithSalesQuery({
      href, key, active, isOnSale, isInStock,
    })}
    >
      <a style={style} className={className} aria-label={ariaLabel} title={title} onClick={onClick}>{children}</a>
    </Link>
  )
}

export default SwitchLink
