/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { getUpdatedQueryParams, getUrl } from 'utils/routing'
import { resetKeepNavigationStates } from 'store/actions/pageReducer'
import { useRef, useState, useEffect } from 'react'
import { hrefWithSalesQuery, switchKeys } from 'helper'
import SkeletonImage from 'components/SkeletonImage/SkeletonImage'
import LinkWrapper from 'components/LinkWrapper/LinkWrapper'
import throttle from 'lodash.throttle'
import { ElasticSearch } from '../../services/main'

const SearchInput = ({ placeholder }) => {
  const inputRef = useRef(null)
  const router = useRouter()
  const isOnSale = useSelector((state) => state.main.switchKeys[switchKeys.SALES.key])
  const isInStock = useSelector((state) => state.main.switchKeys[switchKeys.IN_STOCK.key])
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [queryAlternatives, setQueryAlternatives] = useState(null)
  const [categories, setCategories] = useState(null)
  const [products, setProducts] = useState(null)
  const [earlierSearches, setEarlierSearches] = useState(null)

  const emptyStates = () => {
    setQueryAlternatives(null)
    setCategories(null)
    setProducts(null)
  }

  const addQueryToLocalstorage = (item) => {
    try {
      const userQueriesFromLocalstorage = JSON.parse(localStorage.getItem('userQueries') || '[]').filter((x) => x.query !== item.query)
      const newArray = [item, ...userQueriesFromLocalstorage]
      localStorage.setItem('userQueries', JSON.stringify(newArray))
      setEarlierSearches(newArray)
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleSearch = async (input) => {
    const { data } = await ElasticSearch.getSuggestions(input) || {}
    setQueryAlternatives(data.queryAlternatives)
    setCategories(data.categories)
    setProducts(data.products)
  }

  const handleOnChange = throttle((value) => {
    if (value.trim().length <= 1) {
      emptyStates()
      return
    }
    handleSearch(value)
  }, 1000)

  const onItemClick = ({ query, close = true, save = true }) => () => {
    inputRef.current.value = query
    handleOnChange(query)
    if (close) {
      setIsInputFocused(false)
    }
    if (save) {
      addQueryToLocalstorage({ query })
    }
  }

  const onShadowClick = () => {
    setIsInputFocused(false)
  }

  const performSearch = () => {
    inputRef.current?.blur()
    const searchInput = inputRef.current.value.trim()
    if (searchInput === '') {
      return
    }
    addQueryToLocalstorage({ query: searchInput, url: null })
    emptyStates()
    resetKeepNavigationStates()
    const href = getUrl({ path: '/se/search', queryParams: getUpdatedQueryParams({}, 'query', encodeURIComponent(searchInput)) })
    router.push(hrefWithSalesQuery({ href, isOnSale, isInStock }))
    setIsInputFocused(false)
    // inputRef.current.value = ''
  }

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter' && e.key !== 'return') {
      return
    }
    performSearch()
  }

  const onInputFocus = () => {
    setIsInputFocused(true)
  }

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)
  const gendersLowerCase = ['herr', 'dam', 'barn', 'baby', 'junior', 'unisex']

  const getLastCategory = (lastCategory) => {
    if (!lastCategory) {
      return 'kategori'
    }
    if (gendersLowerCase.includes(lastCategory)) {
      return (
        <>
          för
          {' '}
          {lastCategory}
        </>
      )
    }
    return (
      <>
        i
        {' '}
        {lastCategory}
      </>
    )
  }

  const removeSearchQueryFromLocalstorage = (query) => (e) => {
    e.stopPropagation()
    e.preventDefault()
    try {
      const data = JSON.parse(localStorage.getItem('userQueries') || '[]')
      const filteredData = data.filter((x) => x.query !== query)
      setEarlierSearches(filteredData)
      if (filteredData.length === 0) {
        localStorage.removeItem('userQueries')
        return
      }
      localStorage.setItem('userQueries', JSON.stringify(filteredData))
    } catch (error) {
      console.log(error.message)
    }
  }

  const renderQueryAlternatives = (documents) => {
    if (inputRef?.current.value.length === 0) {
      return null
    }
    if (!documents || !inputRef?.current.value.length === 0 || documents.length === 0) {
      return null
    }
    return (
      <>
        <div className="left-side">
          <div className="search-suggestions__item">
            <div className="search-suggestions__item__left-icon">
              <svg viewBox="0 0 24 24" fill="#FFFFFF"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" /></svg>
            </div>
            <div className="search-suggestions__item__category-key">Sökförslag:</div>
          </div>
        </div>
        {documents.map((x) => (
          <div className="search-suggestions__item cursor__pointer hover" onClick={onItemClick({ query: x.text, close: false })}>
            <div className="left-side">
              <div className="search-suggestions__item__sub-category">
                {x.text}
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }

  const renderCategories = (documents) => {
    if (inputRef?.current.value.length === 0) {
      return null
    }
    if (!documents || !inputRef?.current.value.length === 0 || documents.length === 0) {
      return null
    }
    return (documents.map((x) => (
      <LinkWrapper href={x.href}>
        <div className="search-suggestions__item cursor__pointer hover" onClick={onItemClick({ query: x.matchedKey })}>
          <div className="left-side">
            <div className="search-suggestions__item__left-icon">
              {!x.isChild && (
                <svg viewBox="0 0 24 24" fill="#FFFFFF"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" /></svg>
              )}
            </div>
            <div className="search-suggestions__item__category-key">
              {!x.isChild && capitalizeFirstLetter(x.matchedKey)}
              {/* {capitalizeFirstLetter(x.matchedKey)} */}
            </div>
            <div className="search-suggestions__item__sub-category">
              {(x.isChild || !x.hasChildren) && getLastCategory(x.lastCategory)}
              {/* {getLastCategory(x.lastCategory)} */}
            </div>
          </div>
          <svg
            className="search-suggestions__item__arrow"
            viewBox="0 0 24 24"
            focusable="false"
            aria-hidden="true"
          >
            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
          </svg>
        </div>
      </LinkWrapper>
    )))
  }

  const renderProducts = (documents) => {
    if (inputRef?.current.value.length === 0) {
      return null
    }
    if (!documents || !inputRef?.current.value.length === 0 || documents.length === 0) {
      return null
    }

    return (documents.map((x) => (
      <LinkWrapper href={x.href}>
        <div className="search-suggestions__item product cursor__pointer hover" onClick={onItemClick({ query: x.name, save: false })}>
          <div className="search-suggestions__item__left-section">
            <SkeletonImage
              height={30}
              width={30}
              alt={x.name}
              src={x.imageUrl}
            />
            <div className="search-suggestions__item__left-section__text">
              <div className="search-suggestions__item__left-section__text__name">{x.name}</div>
              <div className="search-suggestions__item__left-section__text__category">{x.categoryArray.at(-1)}</div>
            </div>
          </div>
          <div>
            <div className="price">
              {x.price.toLocaleString('sv-SE')}
              {' '}
              kr
            </div>
          </div>
        </div>
      </LinkWrapper>
    )))
  }

  const renderNoResultMessage = () => {
    if (!queryAlternatives || !categories || !products) {
      return null
    }

    if (queryAlternatives.length > 0 || categories.length > 0 || products.length > 0) {
      return null
    }
    return (
      <div className="search-suggestions__item">
        Inga resultat hittades för &#34;
        {inputRef.current.value}
        &#34;
      </div>
    )
  }

  useEffect(() => {
    try {
      const data = JSON.parse(localStorage.getItem('userQueries') || '[]')
      setEarlierSearches(data)
      // eslint-disable-next-line no-empty
    } catch (error) { }
  }, [])

  const renderEarlierSearches = () => {
    if (inputRef?.current.value.length > 0) {
      return null
    }
    return (earlierSearches.map((x) => (
      <div className="earlier-searches cursor__pointer hover" onClick={onItemClick({ query: x.query, close: false })}>
        <div className="left-side">
          <div className="search-suggestions__item__left-icon">
            <svg viewBox="0 0 24 24" className="earlier-searches__watch-icon">
              <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
              <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
            </svg>
          </div>
          <div className="search-suggestions__item__category-key">
            {x.query}
          </div>
        </div>
        <button aria-label="Ta bort" type="button" onClick={removeSearchQueryFromLocalstorage(x.query)} className="earlier-searches__remove-button">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" version="1.1" viewBox="0 0 94.926 94.926" className="earlier-searches__remove-icon">
            <g>
              <path d="M55.931,47.463L94.306,9.09c0.826-0.827,0.826-2.167,0-2.994L88.833,0.62C88.436,0.224,87.896,0,87.335,0   c-0.562,0-1.101,0.224-1.498,0.62L47.463,38.994L9.089,0.62c-0.795-0.795-2.202-0.794-2.995,0L0.622,6.096   c-0.827,0.827-0.827,2.167,0,2.994l38.374,38.373L0.622,85.836c-0.827,0.827-0.827,2.167,0,2.994l5.473,5.476   c0.397,0.396,0.936,0.62,1.498,0.62s1.1-0.224,1.497-0.62l38.374-38.374l38.374,38.374c0.397,0.396,0.937,0.62,1.498,0.62   s1.101-0.224,1.498-0.62l5.473-5.476c0.826-0.827,0.826-2.167,0-2.994L55.931,47.463z" />
            </g>
          </svg>
        </button>
      </div>
    )))
  }

  return (
    <>
      <input
        ref={inputRef}
        disabled
        placeholder={placeholder}
        onFocus={onInputFocus}
        autoComplete="off"
        spellCheck="false"
        onChange={(e) => handleOnChange(e.target.value)}
        type="text"
        onKeyUp={handleKeyDown}
        x-webkit-speech="true"
        autoCorrect="off"
        autoCapitalize="off"
        {...isInputFocused && { className: 'open' }}
        style={{ cursor: "not-allowed" }}
      />
      <div className="magnifier-icon" onClick={performSearch}>
        <svg viewBox="0 0 24 24" fill="#FFFFFF">
          <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        </svg>
      </div>
      {isInputFocused && (
        <div className="search-suggestions">
          {renderNoResultMessage()}
          {renderQueryAlternatives(queryAlternatives)}
          {renderCategories(categories)}
          {renderProducts(products)}
          {renderEarlierSearches()}
        </div>
      )}
      <div className={`search-modal ${isInputFocused ? 'open' : ''}`} onClick={onShadowClick} />
    </>
  )
}

export default SearchInput
