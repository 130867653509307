import SubscriberInput from "components/SubscriberInput/SubscriberInput"

const NoLongerActiveComponent = () => {
  return <div className="no-longer-active__component">
    <div className="no-longer-active__component__wrapper">
      <div style={{ color: "white", height: 35, alignContent: "center" }}>
        Den här sidan är inte längre aktiv. Jiroy har förvandlats till något nytt och spektakulärt! Följ vår nya resa!
      </div>
      <div className="subscriber-input-wrapper">
        <SubscriberInput />
      </div>
    </div>
  </div>
}

export default NoLongerActiveComponent