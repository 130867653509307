/* eslint-disable max-len */
import {
  useState, memo, useEffect,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import MuiDrawer from 'components/MuiDrawer'
import useDimensions from 'Hooks/useDimensions'
import SkeletonImage from '../SkeletonImage/SkeletonImage'
import { hydrateProductsAction, triggerRightDrawerItemPressedAction } from '../../store/actions/main'

const SEEN_PRODUCTS = 'SEEN_PRODUCTS'
const FAVORITE_PRODUCTS = 'FAVORITE_PRODUCTS'

const ShowButtons = ({
  favoritedProducts, favoriteButtonIsFilled, drawerIsOpen, toggleDrawer, localStorageExists,
}) => {
  const getButtonIsFilled = () => (favoriteButtonIsFilled ? 'filled' : '')

  return (
    <button title="Favoriter" type="button" className={`favorite-button ${localStorageExists ? '' : 'hidden'}`} onClick={toggleDrawer(!drawerIsOpen, FAVORITE_PRODUCTS)}>
      <div className={`heart ${getButtonIsFilled()}`}>
        {favoritedProducts.length > 0 && <span>{favoritedProducts.length}</span>}
        {/* eslint-disable-next-line max-len */}
        <div>
          <svg viewBox="0 0 24 24">
            <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" />
          </svg>

        </div>
      </div>
    </button>
  )
}

const FavoriteItemsDrawer = () => {
  const [localStorageExists, setLocalStorageExists] = useState(false)
  const [favoritedProducts, setFavoritedProducts] = useState([])
  const [visibleProducts, setVisibleProducts] = useState([])
  const [selectedValue, setSelectedValue] = useState(undefined)
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const favoriteButtonIsFilled = useSelector((state) => state.main.favoriteButtonIsFilled)
  const amountIndicateHydrate = useSelector((state) => state.main.amountIndicateHydrate)
  const { isMobile: isSmallerDevice } = useDimensions({ mobileBreakPoint: 991, throttleMs: 250 })
  const dispatch = useDispatch()

  useEffect(() => {
    try {
      // eslint-disable-next-line no-unused-expressions
      localStorage
      setLocalStorageExists(true)
      // eslint-disable-next-line no-empty
    } catch (error) { }
  }, [])

  useEffect(() => {
    let favoriteProducts = []
    try {
      favoriteProducts = JSON.parse(localStorage.getItem(FAVORITE_PRODUCTS)) || []
      // eslint-disable-next-line no-empty
    } catch (error) { }

    setFavoritedProducts(favoriteProducts)
  }, [amountIndicateHydrate])

  const toggleDrawer = (open, selected, stepperWasChanged) => (event) => {
    if (stepperWasChanged || (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))) {
      return
    }
    let seenProducts = []
    let favoriteProducts = []
    try {
      seenProducts = JSON.parse(localStorage.getItem(SEEN_PRODUCTS)) || []
      favoriteProducts = JSON.parse(localStorage.getItem(FAVORITE_PRODUCTS)) || []
      // eslint-disable-next-line no-empty
    } catch (error) { }

    setDrawerIsOpen(open)
    setSelectedValue(selected)
    setVisibleProducts(selected === SEEN_PRODUCTS ? seenProducts : favoriteProducts)
  }

  const removeItem = (item, e) => {
    const liItem = e.target.closest('li')
    liItem.classList.add('hippidy')

    setTimeout(() => {
      liItem.classList.remove('hippidy')
      // let seenProducts = []
      let favoriteProducts = []
      try {
        // seenProducts = JSON.parse(localStorage.getItem(SEEN_PRODUCTS)) || []
        favoriteProducts = JSON.parse(localStorage.getItem(FAVORITE_PRODUCTS)).filter((x) => x.href !== item.href) || []
        localStorage.setItem(FAVORITE_PRODUCTS, JSON.stringify(favoriteProducts))
      } catch (error) {
        return
      }

      setFavoritedProducts(favoriteProducts)
      setVisibleProducts(favoriteProducts)
      dispatch(hydrateProductsAction())
    }, 300)
  }

  const onDrawerItemClick = () => {
    dispatch(triggerRightDrawerItemPressedAction())
    setDrawerIsOpen(false)
  }

  return (
    <>
      <ShowButtons
        localStorageExists={localStorageExists}
        toggleDrawer={toggleDrawer}
        favoritedProducts={favoritedProducts}
        favoriteButtonIsFilled={favoriteButtonIsFilled}
        selectedValue={selectedValue}
        drawerIsOpen={drawerIsOpen}
      />
      <MuiDrawer
        className="right-menu"
        anchor="right"
        open={drawerIsOpen}
        onClose={toggleDrawer(false)}
        disableScrollLock
      >
        <div
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >

          {visibleProducts.length < 1 && (
            <div className="no-results">
              Du har inte favoriserat något ännu
            </div>
          )}

          <ul className={`right-drawer-list ${isSmallerDevice ? 'add-padding' : ''}`.trim()}>
            {visibleProducts.map((x) => (
              <li key={`link-${x.sku}`}>
                <a href={x.href} onClick={onDrawerItemClick}>
                  <div className="list-image">
                    <SkeletonImage
                      height={80}
                      width={80}
                      alt={x.name}
                      src={x.imageUrl}
                    />
                  </div>

                  <div>
                    <div>
                      <div className="list-title">{x.name}</div>
                      <div className="list-price-wrapper">
                        {x.isReduced && (
                          <span className="original-price">
                            {Math.round(x.originalPrice).toLocaleString('sv-SE')}
                            :-
                          </span>
                        )}
                        <span className="price">
                          {Math.round(x.price).toLocaleString('sv-SE')}
                          :-
                        </span>
                      </div>
                    </div>

                  </div>
                </a>

                {selectedValue === FAVORITE_PRODUCTS && (
                  <button type="button" className="close" onClick={(e) => removeItem(x, e)}>
                    <svg viewBox="0 0 320 512">
                      <path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" />
                    </svg>
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
      </MuiDrawer>
    </>
  )
}

export default memo(FavoriteItemsDrawer)
